import React, { FC, ReactNode } from 'react';
import tw, { css, styled, theme } from 'twin.macro';

interface ButtonProps {
  glow?: boolean;
}
const Button = styled.button<ButtonProps>`
  ${tw`
    text-white
    font-heading
    inline-flex
    justify-center
    items-center
    px-0
    hover:cursor-pointer
    active:text-grey
    no-underline
    p-4
  `}
  svg {
    margin-left: 4px;
    fill: white;
  }
  &:active {
    svg {
      fill: ${theme`colors.grey`};
    }
  }
  ${({ glow }) =>
    glow &&
    css`
      text-shadow: ${theme`boxShadow.blue`};
    `}
`;
const TextButton: FC<{
  text: string;
  icon: ReactNode;
  as?: any;
  to?: string;
  disabled?: boolean;
  glow?: boolean;
  onClick?: any;
}> = ({ as, text, onClick, icon, to, glow, disabled }) => (
  <Button
    title={text}
    onClick={onClick}
    as={as}
    to={to}
    glow={glow}
    disabled={disabled}
  >
    <span>{text}</span>
    {icon}
  </Button>
);

export default TextButton;
