import Query from 'src/utils/Queries';
import tw, { styled } from 'twin.macro';

interface NavProps {
  isShow: boolean;
}

export const Nav = styled('nav')<NavProps>`
  ${tw`fixed bottom-0 right-0 left-0`};
  transition: 0.3s all;
  transform: ${props => (props.isShow ? 'none' : 'translateY(100%)')};
  border-top: 1px solid #282828;
`;

export const Wrap = styled('div')`
  ${tw`flex bg-black`};
  max-width: 375px;
  width: 100%;
  margin: 0 auto;
  ${Query.tablet} {
    max-width: 812px;
  }
`;

export const Item = styled.button`
  ${tw`font-heading no-underline flex flex-auto text-center flex-col items-center text-white`}
  padding: 8px 0 15px;
  font-size: 15px;
  width: 50%;
  svg {
    margin-bottom: 6px;
  }
  ${Query.tablet} {
    padding: 8px 0 22px;
  }
`;

export default Nav;
