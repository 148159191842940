// import { Link } from 'gatsby';
import { Link } from 'gatsby';
import React, { FC } from 'react';
// import { connect } from 'react-redux';
import CheckIconButton from 'src/components/CheckIconButton';
import LinkButton from 'src/components/LinkButton';
import { Body, Grid } from 'src/components/layout/Wrap';
import { withModuleSubmodule } from 'src/hoc/withModuleSubmodule';
import { Center, PushToBottom } from 'src/styles/Alignment';
import { WelcomeHeader } from 'src/styles/Layout';
import { P } from 'src/styles/Typography';
import { WelcomePage } from './styles';

const Welcome: FC<{
  module: ModuleProps;
  submodule: SubModuleProps;
}> = ({ module, submodule }) => {
  return (
    <Body module={module.title} experience backLink>
      <WelcomeHeader>
        <img
          src={
            submodule.headerImage === ''
              ? 'https://placehold.it/810x349?text=Experience+Placeholder+(image+to+be+provided)+'
              : `/images/${submodule.headerImage}`
          }
          alt="Experience welcome"
          width="810"
          height="349"
        />
      </WelcomeHeader>

      <WelcomePage>
        <Grid>
          <Center>
            <h2>Have you completed this experience?</h2>
            <PushToBottom>
              <P large>
                <CheckIconButton
                  as={Link}
                  to={`/app/module/${module.slug}/${submodule.slug}/questionnaire`}
                />
              </P>
              <LinkButton as={Link} to={`/app/module/${module.slug}`}>
                I&apos;m at a different experience
              </LinkButton>
            </PushToBottom>
          </Center>
        </Grid>
      </WelcomePage>
    </Body>
  );
};

export default withModuleSubmodule(Welcome);
