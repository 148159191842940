import { Link } from 'gatsby';
import React, { Component, FC, useCallback, useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
// import { connect } from 'react-redux';
import { animated, useTransition } from 'react-spring/web.cjs';
import { useSwipeable } from 'react-swipeable';
import CheckIconButton from 'src/components/CheckIconButton';
import LinkButton from 'src/components/LinkButton';
import FadedRings from 'src/components/animations/FadedRings';
import { Body, Grid } from 'src/components/layout/Wrap';
import { PageNoTop } from 'src/styles/Layout';
import { PageTitle } from 'src/styles/Typography';
import { SlideIcons } from './data';
import * as S from './styles';

const Slides: FC<{
  slides: any;
  moduleSlug: string;
  currentModule: any;
}> = ({ slides, moduleSlug, currentModule }) => {
  const [slide, setSlide] = useState(slides[0]);
  const [reverse, setReverse] = useState(false);
  const slidesSize = slides.length - 1;
  const slideQuestions = useTransition(
    slide,
    slideItem => parseInt(slideItem.id),
    {
      unique: true,
      from: {
        opacity: 0,
        transform:
          reverse === true ? 'translate3d(-100%,0,0)' : 'translate3d(100%,0,0)',
        position: 'absolute'
      },
      enter: {
        opacity: 1,
        transform: 'translate3d(0%,0,0)',
        position: 'relative'
      },
      leave: {
        opacity: 0,
        transform:
          reverse === true ? 'translate3d(50%,0,0)' : 'translate3d(-50%,0,0)',
        position: 'absolute'
      }
    }
  );

  const goTo = useCallback(
    id => {
      setSlide(slides[id]);
    },
    [setSlide, slides]
  );

  const goToNext = useCallback(
    e => {
      const targetVal = e.target.value;

      if (parseInt(slide.id) < parseInt(targetVal)) {
        setReverse(false);
      } else {
        setReverse(true);
      }
      setSlide(slides[parseInt(targetVal)]);
    },
    [slide, setSlide, slides]
  );
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setReverse(false);
      if (!(parseInt(slide.id) === slidesSize)) {
        goTo(parseInt(slide.id) + 1);
      }
    },
    onSwipedRight: () => {
      setReverse(true);
      if (!(parseInt(slide.id) === 0)) {
        goTo(parseInt(slide.id) - 1);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <Body module={currentModule.title}>
      <Grid>
        <S.RingWrap>
          <FadedRings />
        </S.RingWrap>
        <PageNoTop>
          <S.SlidesWrap>
            {slideQuestions.map(({ item, props, key }) => (
              <animated.div key={key} style={props}>
                <S.Slide {...handlers}>
                  {item.icon !== '' && (
                    <Component>{SlideIcons[parseInt(item.id)].icon}</Component>
                  )}
                  {item.title !== '' && <PageTitle>{item.title}</PageTitle>}
                  <p>{item.description}</p>
                </S.Slide>
              </animated.div>
            ))}
            <S.SlidesNav>
              {slides.map((slideItem: any) => (
                <S.SlidesNavItem key={slideItem.id}>
                  <S.SlideButton
                    style={{ color: 'white' }}
                    type="button"
                    onClick={goToNext}
                    value={slideItem.id}
                    current={parseInt(slide.id) === parseInt(slideItem.id)}
                    title={`Go to slide ${
                      parseInt(slideItem.id) + 1
                    }/${slidesSize}`}
                  >
                    <span />
                  </S.SlideButton>
                </S.SlidesNavItem>
              ))}
            </S.SlidesNav>

            {parseInt(slide.id) === slides.length - 1 ? (
              <S.Finish>
                <ScrollAnimation offset={0} delay={500} animateIn="fadeInUp">
                  <CheckIconButton
                    glow="orange"
                    as={Link}
                    to={`/app/module/${moduleSlug}`}
                  />
                </ScrollAnimation>
              </S.Finish>
            ) : (
              <S.Skip>
                <LinkButton as={Link} to={`/app/module/${moduleSlug}`}>
                  Skip instructions
                </LinkButton>
              </S.Skip>
            )}
          </S.SlidesWrap>
        </PageNoTop>
      </Grid>
    </Body>
  );
};

export default Slides;
