// import { Link } from 'gatsby';

import { navigate } from 'gatsby';
import React, { FC, useCallback, useEffect, useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { connect } from 'react-redux';
import Button from 'src/components/Button';
import OttawaModal from 'src/components/OttawaModal';
import AnimatedHeight from 'src/components/animations/AnimatedHeight';
import { Grid } from 'src/components/layout/Wrap';
import Close from 'src/components/svg/Close';
import { useMatchAnswers, useMatches } from 'src/hooks/useData';
import { RootState } from 'src/state/reducers';
import { removeMatch, submitMatch } from 'src/state/reducers/answers';
import { answersSelector } from 'src/state/selectors';
import { Page } from 'src/styles/Layout';
import { QuestionCategory } from 'src/styles/Typography';
import dataLayerPush from 'src/utils/dataLayerPush';
import { Match, Matches } from './Matches';
// import Tick from 'src/components/svg/Tick';
import * as S from './styles';
const ErrorBar: FC<{ open: boolean; reset: Function }> = ({ open, reset }) => {
  return (
    <S.ErrorBar>
      <AnimatedHeight heightCondition={open}>
        <S.ErrorBarContent>
          <Grid direction="row" header>
            There&apos;s a better match. Try again!
            <S.ErrorBarButton title="Close error" onClick={reset}>
              <Close fill="#000" />
            </S.ErrorBarButton>
          </Grid>
        </S.ErrorBarContent>
      </AnimatedHeight>
    </S.ErrorBar>
  );
};

const MatchQuestion: FC<{
  submodule: SubModuleProps;
  answers: Array<AnswerProps>;
  submitMatchAction: Function;
  removeMatchAction: Function;
  module: ModuleProps;
}> = ({ submodule, submitMatchAction, removeMatchAction, module, answers }) => {
  const matches = useMatches();
  const matchButtons = useMatchAnswers(matches, module, submodule, answers);
  // 'incorrect', 'good', 'strong'
  const [interfaceMatch, setInterfaceMatch] = useState('');
  const [modal, setModal] = useState(false);

  const reset = useCallback(() => {
    setInterfaceMatch('');
  }, [setInterfaceMatch]);

  const toggleModal = useCallback(() => {
    setModal(!modal);
  }, [setModal, modal]);

  const selectMatch = useCallback(
    e => {
      const answerType = e.target.dataset.type;
      const isVisited = e.target.dataset.visited;

      if (isVisited !== 'true') {
        if (interfaceMatch === 'incorrect' && answerType === 'incorrect') {
          // we want to hide then show the error banner if it's set.
          setInterfaceMatch('');
          setTimeout(() => {
            setInterfaceMatch(answerType);
          }, 500);
        } else {
          setInterfaceMatch(answerType);
        }
        dataLayerPush({
          event: 'trackEvent',
          eventCategory: 'Module Match',
          eventAction: 'Select Match',
          eventLabel: submodule.title,
          eventValue: answerType
        });
        submitMatchAction({
          moduleId: module.id,
          id: submodule.id,
          match: e.target.value
        });
      }
    },
    [module, submodule, submitMatchAction, interfaceMatch, setInterfaceMatch]
  );

  useEffect(() => {
    // if the current answers for matches contains a strong match, remove it. This is so we can move backwards
    // using the browser navigation.
    const strongMatch = matchButtons.find(
      (m: any) => m.visited && m.matchType === 'strong'
    );

    if (strongMatch) {
      removeMatchAction({
        moduleId: module.id,
        id: submodule.id,
        match: strongMatch.value
      });
    }
    // we really only want this to run once, on mount. so disabling the exhaustive deps.
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (interfaceMatch === 'good' || interfaceMatch === 'strong') {
      navigate(
        `/app/module/${module.slug}/${submodule.slug}/questionnaire/match/${interfaceMatch}`
      );
    }
  }, [interfaceMatch, module.slug, submodule.slug]);

  return (
    <Page>
      <ErrorBar open={interfaceMatch === 'incorrect'} reset={reset} />
      <QuestionCategory>Make a match</QuestionCategory>
      <h2>Which action area best reflects the {submodule.title} experience?</h2>
      <p>
        <ScrollAnimation offset={0} animateIn="fadeInUp">
          <Button
            fullWidth
            small
            glow="blue"
            type="button"
            onClick={toggleModal}
          >
            Refer to the Ottawa Charter
          </Button>
        </ScrollAnimation>
      </p>
      <Matches>
        {matchButtons.map((q: ConvertedMatchProps) => (
          <Match
            key={q.value}
            onClick={selectMatch}
            value={q.value}
            visited={q.visited}
            matchType={q.matchType}
          >
            {q.title}
          </Match>
        ))}
      </Matches>

      <OttawaModal show={modal} toggleModal={toggleModal} />
    </Page>
  );
};
const mapDispatchToProps = {
  submitMatchAction: submitMatch,
  removeMatchAction: removeMatch
};

export default connect(
  (state: RootState) => ({ answers: answersSelector(state) }),
  mapDispatchToProps
)(MatchQuestion);
