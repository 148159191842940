import gsap from 'gsap';
import React from 'react';
import { useEffect, useRef, useState } from 'react';

const AnimationExample = () => {
  const animateGroup = useRef(null);
  const animateOuter = useRef(null);
  const animateInner = useRef(null);
  const [visible, setVisible] = useState('none');

  useEffect(() => {
    const tl = gsap.timeline();

    setVisible('block');
    tl.fromTo(
      animateGroup.current,
      { transformOrigin: '50% 50%', scale: 0 },
      { scale: 1, duration: 2 }
    );
    gsap.to(animateOuter.current, {
      rotation: '360deg',
      ease: 'linear',
      duration: 12,
      transformOrigin: '50% 50%',
      repeat: -1
    });
    gsap.to(animateInner.current, {
      rotation: '-360deg',
      ease: 'linear',
      duration: 20,
      transformOrigin: '50% 50%',
      repeat: -1
    });
  }, []);

  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 396 396"
      width="396"
      height="396"
      style={{ display: visible }}
    >
      <g ref={animateGroup}>
        <path
          fill="none"
          ref={animateOuter}
          stroke="#003A71"
          strokeWidth="1"
          d="M287.2,21.1C194.1-25.9,79.1,7.8,26,99.5c-53.1,91.7-24.8,208,62.7,264.9 M107.3,374.9
          c93.1,47,208.1,13.4,261.1-78.4c53.1-91.7,24.8-208-62.6-264.9"
        />
        <path
          id="innerCircle"
          fill="none"
          ref={animateInner}
          stroke="#333333"
          strokeWidth="2"
          d="M116.1,38.5C32.3,81.4-4.1,183,34.9,270.3c39,87.3,139,128.3,227,94.9 M279.9,357.5
          c83.8-42.9,120.1-144.5,81.2-231.8c-39-87.3-139-128.3-227-94.9"
        />
      </g>
    </svg>
  );
};

export default AnimationExample;
