import React, { FC } from 'react';
import Bubbles from 'src/components/svg/Bubbles';
import Query from 'src/utils/Queries';
import { css, styled } from 'twin.macro';

interface BubblesProps {
  mobileOnly?: boolean;
}
export const StyleBubbles = styled.svg<BubblesProps>`
  position: fixed;
  top: calc(100% - 170px);
  left: 50%;
  width: 750px;
  height: auto;
  transform: translate(-50%, 0);
  ${({ mobileOnly }) =>
    mobileOnly &&
    css`
      display: none;
    `}
  ${Query.tablet} {
    bottom: -200px;
    display: block;
    left: 50%;
    width: 1050px;
  }
`;

export const PageBubbles: FC<BubblesProps> = ({ mobileOnly }) => (
  <StyleBubbles mobileOnly={mobileOnly} as={Bubbles} />
);
