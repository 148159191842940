import React from 'react';
import SVG from 'src/components/svg/SVG';
const Explain = () => (
  <SVG width={72} height={73} viewBox="0 0 72 73">
    <defs>
      <filter
        id="zc0z12b1za"
        width="199.8%"
        height="200.1%"
        x="-49.9%"
        y="-50.1%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="12"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0.568627451 0 0 0 0 0.854901961 0 0 0 1 0"
        />
        <feOffset in="SourceAlpha" result="shadowOffsetOuter2" />
        <feGaussianBlur
          in="shadowOffsetOuter2"
          result="shadowBlurOuter2"
          stdDeviation="6"
        />
        <feColorMatrix
          in="shadowBlurOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 0 0 0 0 0 0.568627451 0 0 0 0 0.854901961 0 0 0 1 0"
        />
        <feOffset in="SourceAlpha" result="shadowOffsetOuter3" />
        <feGaussianBlur
          in="shadowOffsetOuter3"
          result="shadowBlurOuter3"
          stdDeviation="3"
        />
        <feColorMatrix
          in="shadowBlurOuter3"
          result="shadowMatrixOuter3"
          values="0 0 0 0 0 0 0 0 0 0.568627451 0 0 0 0 0.854901961 0 0 0 1 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
          <feMergeNode in="shadowMatrixOuter3" />
        </feMerge>
      </filter>
      <path
        id="0u1unf3i7b"
        d="M48 .75c1.657 0 3 1.343 3 3v11.93l1.413-2.569 2.59-4.71c1.461-2.658 4.812-3.63 7.469-2.17l6.791 3.736c1.287.708 2.222 1.874 2.632 3.285.41 1.411.245 2.897-.463 4.185l-2.59 4.709-24.575 44.689c-.01.018-.025.03-.037.048-.027.044-.057.082-.09.122-.035.041-.07.082-.108.117-.035.03-.072.058-.11.085-.047.033-.094.062-.146.087-.019.01-.035.025-.055.033l-12.289 5.226c-.14.06-.29.09-.44.09-.187 0-.374-.048-.542-.14-.302-.166-.512-.463-.568-.803l-2.169-13.176c-.002-.01 0-.021 0-.032-.013-.093-.018-.187-.007-.28l.005-.023c.012-.084.034-.165.064-.246l.031-.071c.011-.024.018-.05.03-.073l13.226-24.05H29.203l-2.573 3.238c-.263.466-.73.698-1.195.698-.48 0-.96-.247-1.216-.736l-2.423-3.2H3c-1.657 0-3-1.342-3-3v-27c0-1.656 1.343-3 3-3zM31.316 66.56l.554 3.362 3.135-1.333-3.689-2.029zm-1.007-6.117l.697 4.235 5.754 3.164 3.95-1.68-.595-.326-9.806-5.393zm23.533-45.262L30.351 57.898l5.257 2.89 18.25-33.187c.3-.544.984-.744 1.529-.444.544.3.743.984.444 1.528L37.58 61.873l5.257 2.892 23.491-42.717-5.257-2.892-2.71 4.93c-.205.371-.59.582-.988.582-.183 0-.369-.045-.54-.14-.545-.299-.744-.983-.444-1.527l2.71-4.93-5.257-2.89zM48 3H3c-.413 0-.75.337-.75.75v27c0 .414.337.75.75.75h18.796c.705 0 1.369.33 1.794.892l1.863 2.46 1.99-2.502c.426-.537 1.075-.85 1.76-.85H42.3l6.45-11.729V3.75c0-.413-.336-.75-.75-.75zm13.388 5.203c-1.571-.864-3.551-.288-4.414 1.282l-2.048 3.725 6.242 3.432h.001l.002.001 6.242 3.433 2.047-3.724c.419-.76.516-1.639.274-2.473-.242-.833-.794-1.523-1.555-1.94zM25.125 15.75c1.036 0 1.875.84 1.875 1.875 0 1.036-.84 1.875-1.875 1.875-1.035 0-1.875-.84-1.875-1.875 0-1.036.84-1.875 1.875-1.875zm-9.75 0c1.036 0 1.875.84 1.875 1.875 0 1.036-.84 1.875-1.875 1.875-1.035 0-1.875-.84-1.875-1.875 0-1.036.84-1.875 1.875-1.875zm19.5 0c1.036 0 1.875.84 1.875 1.875 0 1.036-.84 1.875-1.875 1.875-1.035 0-1.875-.84-1.875-1.875 0-1.036.84-1.875 1.875-1.875z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(0 1)">
      <use fill="#000" filter="url(#zc0z12b1za)" xlinkHref="#0u1unf3i7b" />
      <use fill="#FFF" xlinkHref="#0u1unf3i7b" />
    </g>
  </SVG>
);

export default Explain;
