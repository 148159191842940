import React from 'react';
import SVG from 'src/components/svg/SVG';
const Incorrect = () => (
  <SVG width={24} height={25} viewBox="0 0 24 25">
    <path
      fill="#8A8A8A"
      d="M12 3.25c5.385 0 9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75S2.25 18.385 2.25 13 6.615 3.25 12 3.25zm0 1.5c-4.556 0-8.25 3.694-8.25 8.25s3.694 8.25 8.25 8.25 8.25-3.694 8.25-8.25S16.556 4.75 12 4.75zm-1.616 5.605l.076.052.07.063L12 11.94l1.47-1.47c.293-.293.767-.293 1.06 0 .27.27.291.695.063.99l-.063.07L13.06 13l1.47 1.47c.293.293.293.767 0 1.06-.27.27-.695.291-.99.063l-.07-.063L12 14.06l-1.47 1.47c-.293.293-.767.293-1.06 0-.27-.27-.291-.695-.063-.99l.063-.07L10.94 13l-1.47-1.47c-.293-.293-.293-.767 0-1.06.248-.248.626-.286.914-.115z"
    />
  </SVG>
);

export default Incorrect;
