// For "Module 1" like structures
// import { navigate } from 'gatsby';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import LongAnswer from 'src/components/LongAnswer';
import { TopBar } from 'src/components/Splash';
import { Grid } from 'src/components/layout/Wrap';
// import { useCurrentMatch } from 'src/hooks/useData';
import { RootState } from 'src/state/reducers';
import { answersSelector } from 'src/state/selectors';
import { PageQuestion } from 'src/styles/Layout';
const Explain: FC<{
  module: ModuleProps;
  submodule: SubModuleProps;
}> = ({ module, submodule }) => {
  return (
    <>
      <TopBar>
        <span style={{ width: '50%' }} />
      </TopBar>
      <PageQuestion>
        {/* <PageBubbles mobileOnly /> */}
        <Grid>
          <LongAnswer
            suggestedAnswer={submodule.answer ? submodule.answer : ''}
            module={module}
            hasOttowa={module.id === '2'}
            submodule={submodule}
            wordLimit={100}
            titles={{
              title: `${submodule.explain}`
            }}
          />
        </Grid>
      </PageQuestion>
    </>
  );
};

export default connect(
  (state: RootState) => ({ answers: answersSelector(state) }),
  null
)(Explain);
