import Query from 'src/utils/Queries';
import tw, { css, styled, theme } from 'twin.macro';
// Matches
export const ErrorBar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  background: ${theme`colors.error`};
  left: 0;
  z-index: 1000;
  color: black;
  overflow: hidden;
`;
export const ErrorBarContent = styled.div`
  min-height: 64px;
  padding: 20px 0;
`;
interface ButtonProps {
  onClick: any;
}
export const ErrorBarButton = styled.button<ButtonProps>`
  float: right;
`;
export const MatchStyle = styled.div<ConvertedMatchProps>``;
export const MatchButton = styled.button<ConvertedMatchProps>`
  ${tw`font-heading relative text-white block w-full text-left`}
  font-size: 18px;
  background: #181818;
  padding: 13px 55px 13px 27px;
  display: block;
  border-radius: 100px;
  color: white;
  margin-bottom: 20px;
  svg {
    position: absolute;
    right: 27px;
    top: 50%;
    transform: translate(0, -50%);
  }
  &:before,
  &:after {
    content: ' ';
    ${tw`
      absolute
      block
      left-0
      bottom-0
      rounded-large
    `}
    top: 0px;
    width: 23px;
    height: 100%;
    border: 2px solid ${theme`colors.grey`};
    transition: 0.5s all;
  }
  &:before {
    border-radius: 100px 0 0 100px;
    border-right: 0;
  }
  &:after {
    border-left: 0;
    border-radius: 0 100px 100px 0;
    left: auto;
    right: 0;
  }
  &:hover {
    cursor: pointer;
  }
  ${Query.tablet} {
    margin-bottom: 24px;
    padding-top: 18px;
    padding-bottom: 18px;
  }
  &:focus,
  &:active {
    box-shadow: ${theme`boxShadow.blue-light`};
    outline: 0;
  }
  ${({ visited }) =>
    visited &&
    css`
      color: ${theme`colors.grey`};
      &:focus {
        color: white;
      }
    `}
  ${({ matchType }) =>
    matchType === 'incorrect' &&
    css`
      &:before,
      &:after {
        border-color: #e15d5d;
      }
    `}
  ${({ matchType }) =>
    matchType === 'good' &&
    css`
      &:before,
      &:after {
        border-color: ${theme`colors.blue`};
      }
    `}
  ${({ matchType }) =>
    matchType === 'strong' &&
    css`
      &:before,
      &:after {
        border-color: #8bc34a;
      }
    `}
`;

export const MatchesStyle = styled.section`
  margin: 41px 0;
`;

// Responses
