import React from 'react';
import SVG from 'src/components/svg/SVG';
const Best = () => (
  <SVG width={24} height={25} viewBox="0 0 24 25">
    <path
      fill="#fff"
      d="M12,3.2c5.4,0,9.8,4.4,9.8,9.8s-4.4,9.7-9.8,9.7S2.2,18.4,2.2,13S6.6,3.2,12,3.2z M12,4.7
	c-4.6,0-8.2,3.7-8.2,8.2s3.7,8.2,8.2,8.2s8.2-3.7,8.2-8.2S16.6,4.7,12,4.7z M11.3,8.2c0.3-0.5,1.1-0.5,1.3,0l1.2,2.4l2.7,0.4
	c0.6,0.1,0.8,0.8,0.5,1.2L17,12.2l-2,1.8l0.5,2.6c0.1,0.6-0.5,1-1,0.8l-0.1,0L12,16.2l-2.4,1.2c-0.5,0.3-1.1-0.1-1.1-0.7l0-0.1
	L8.9,14l-2-1.8c-0.4-0.4-0.2-1.1,0.3-1.3l0.1,0l2.7-0.4L11.3,8.2z M12,10.1l-0.7,1.4c-0.1,0.2-0.3,0.4-0.6,0.4l-1.6,0.2l1.1,1
	c0.2,0.2,0.2,0.4,0.2,0.6l0,0.1l-0.3,1.5l1.4-0.7c0.2-0.1,0.4-0.1,0.6,0l0.1,0l1.4,0.7l-0.3-1.5c0-0.2,0-0.4,0.2-0.6l0.1-0.1l1.1-1
	L13.3,12c-0.2,0-0.4-0.1-0.5-0.3l0-0.1L12,10.1z"
    />
  </SVG>
);

export default Best;
