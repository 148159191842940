import React, { FC } from 'react';
import Modal from 'src/components/Modal';
import * as M from 'src/components/Modal/styles';
import OttawaCharter from 'src/components/svg/OttawaCharter';
const OttawaModal: FC<{
  show: boolean;
  toggleModal: Function;
  moduleId?: string;
}> = ({ show, toggleModal, moduleId = '1' }) => {
  return (
    <Modal show={show} closeDisabled={false} closeCallback={toggleModal}>
      <M.FullSVG as={OttawaCharter} />
      {moduleId !== '1' ? (
        <>
          <p>
            The Ottawa Charter identifies three core strategies for health
            promotion:{' '}
          </p>
          <h2>Enable</h2>
          <p>
            Health promotion focuses on achieving equity in health. Health
            promotion action aims at reducing differences in current health
            status and ensuring equal opportunities and resources to enable all
            people to achieve their fullest health potential. This includes a
            secure foundation in a supportive environment, access to
            information, life skills and opportunities for making healthy
            choices. People cannot achieve their fullest health potential unless
            they are able to take control of those things which determine their
            health. This must apply equally to women and men.
          </p>
          <h2>Mediate</h2>
          <p>
            The prerequisites and prospects for health cannot be ensured by the
            health sector alone. More importantly, health promotion demands
            coordinated action by all concerned: by governments, by health and
            other social and economic sectors, by nongovernmental and voluntary
            organization, by local authorities, by industry and by the media.
            People in all walks of life are involved as individuals, families
            and communities. Professional and social groups and health personnel
            have a major responsibility to mediate between differing interests
            in society for the pursuit of health. Health promotion strategies
            and programmes should be adapted to the local needs and
            possibilities of individual countries and regions to take into
            account differing social, cultural and economic systems.
          </p>
          <h2>Advocate</h2>
          <p>
            Good health is a major resource for social, economic and personal
            development and an important dimension of quality of life.
            Political, economic, social, cultural, environmental, behavioural
            and biological factors can all favour health or be harmful to it.
            Health promotion action aims at making these conditions favourable
            through advocacy for health.
          </p>
        </>
      ) : (
        <>
          <h2>Build Healthy Public Policy</h2>
          <p>
            Relates directly to the decisions made by government and
            organisations regarding laws and policies that affect health and
            wellbeing e.g. compulsory wearing of seatbelts.
          </p>
          <h2>Create supportive environments</h2>
          <p>
            Relates to creating environments or settings that promote health and
            wellbeing by helping people to practise healthy behaviours e.g. road
            treatments such as roundabouts.{' '}
          </p>
          <h2>Strengthen Community Action</h2>
          <p>
            Focuses on building links between individuals and the community and
            centres on the community working together to achieve a common goal
            and to promote health and wellbeing.
          </p>
          <h2>Develop Personal Skills</h2>
          <p>
            Relates to the the building of life skills and information through
            health promotion and education. Developing knowledge and skills
            allows people to make informed choices that impact their health and
            wellbeing.
          </p>
          <h2>Reorient health services</h2>
          <p>
            Refers to shifting the focus from a biomedical approach that
            emphasises the diagnosing and treatment of illness and disease to
            one of health promotion e.g trauma surgeons educating parents about
            child restraints.
          </p>
        </>
      )}
    </Modal>
  );
};

export default OttawaModal;
