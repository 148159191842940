import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { Link } from 'gatsby';
import React, { FC, useState } from 'react';
import * as B from 'src/components/BottomNav';
import Info from 'src/components/svg/Info';
import Pin from 'src/components/svg/Pin';
const Navigation: FC<{
  instructions: string;
}> = ({ instructions }) => {
  const [hideOnScroll, setHideOnScroll] = useState(true);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;

      if (isShow !== hideOnScroll) {
        setHideOnScroll(isShow);
      }
    },
    [hideOnScroll]
  );

  return (
    <B.Nav isShow={hideOnScroll}>
      <B.Wrap>
        <B.Item disabled>
          <Pin />
          Experiences
        </B.Item>

        <B.Item as={Link} to={instructions}>
          <Info />
          Instructions
        </B.Item>
      </B.Wrap>
    </B.Nav>
  );
};

export default Navigation;
