import { Form as FormikForm } from 'formik';
// import Query from 'src/utils/Queries';
import tw, { css, styled, theme } from 'twin.macro';
export const Form = styled(FormikForm)`
  ${tw`
    flex
    flex-auto
  `}
  margin-bottom:36px;
`;

interface ExplainAnswerProps {
  provided?: boolean;
}
export const QuestionRestrict = styled.section`
  flex: 0 1 auto;
`;

export const ExplainAnswer = styled.section<ExplainAnswerProps>`
  ${tw`relative`};
  margin-bottom: 32px;
  padding-left: 28px;
  h2 {
    ${tw`uppercase`};
    color: ${theme`colors.blue`};
    margin-bottom: 8px;
  }
  &:before {
    ${tw`absolute block left-0`};
    top: 5px;
    bottom: 5px;
    content: ' ';
    width: 4px;
    border-radius: 2px;
    background: ${theme`colors.blue`};
  }
  ${({ provided }) =>
    provided &&
    css`
      h2 {
        color: white;
        text-shadow: ${theme`boxShadow.blue`};
      }
      &:before {
        background: white;
        box-shadow: ${theme`boxShadow.blue`};
      }
    `}
`;
export const ExplainAnswers = styled.section`
  margin: 16px 0;
`;
