import Query from 'src/utils/Queries';
import tw, { css, styled } from 'twin.macro';

export const ExperienceList = styled.section`
  margin: 24px 0 80px;
`;

export const ExperienceHeader = styled.img`
  display: none;
  ${Query.tablet} {
    display: block;
  }
`;
export const ExperienceComplete = styled.footer`
  ${tw`fixed bottom-0 right-0 left-0`};
  padding: 177px 0 36px;
  text-align: center;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000 59%);
`;
interface ExperienceProps {
  complete?: boolean;
}
export const Experience = styled.article<ExperienceProps>`
  &,
  a {
    ${tw`
      no-underline
      text-white
      flex
      flex-initial
      flex-auto
      flex-row
  `}
  }
  margin-bottom: 20px;
  ${Query.tablet} {
    margin-bottom: 24px;
  }
`;

export const ExperienceImage = styled.div<ExperienceProps>`
  &,
  img {
    ${tw`
    flex
    justify-center
    flex-initial
    items-center
  `}
    flex: 0 0 auto;
    width: 72px;
    height: 72px;
    border-radius: 16px;
  }
  ${tw`
      bg-navy
    `}
  ${({ complete }) =>
    complete &&
    css`
      ${tw`
      bg-blue
    `}
    `}
`;
export const ExperienceBody = styled.div<ExperienceProps>`
  ${tw`
    flex
    flex-col
    justify-center
    flex-auto
    ml-4
  `}
  * + * {
    margin: 0;
  }
  ${({ complete }) =>
    complete &&
    css`
      ${tw`
      text-grey
    `}
    `}
`;

export const ExperienceTitle = styled.h1<ExperienceProps>`
  ${tw`
    uppercase
    text-blue
  `}
  font-size:19px;
  margin-bottom: 6px;
  ${({ complete }) =>
    complete &&
    css`
      ${tw`
      text-grey
    `}
    `}
`;
