import { navigate } from 'gatsby';
import React, { FC, useCallback } from 'react';
import { connect } from 'react-redux';
import TextButton from 'src/components/TextButton';
import { PageBubbles } from 'src/components/layout/Bubbles';
import { Body, Grid } from 'src/components/layout/Wrap';
// import { userNameSelector } from 'src/state/selectors';
import ArrowRight from 'src/components/svg/ArrowRight';
import Complete from 'src/components/svg/Complete';
import { useData, useModuleComplete } from 'src/hooks/useData';
import { RootState } from 'src/state/reducers';
import { completeModulesSelector } from 'src/state/selectors';
import { Page } from 'src/styles/Layout';
import { P, PageTitle } from 'src/styles/Typography';
import dataLayerPush from 'src/utils/dataLayerPush';
import * as S from './styles';

const Module: FC<{
  module: ModuleProps;
  completeModules: Array<string>;
  index: number;
}> = ({ module, completeModules, index }) => {
  const complete = useModuleComplete(module, completeModules);

  const startModule = useCallback(() => {
    dataLayerPush({
      event: 'trackEvent',
      eventCategory: 'Module Progression',
      eventAction: 'Start',
      eventLabel: module.title
    });
    navigate(`/app/module/${module.slug}/tutorial`);
  }, [module]);

  return (
    <S.Module key={module.slug} complete={complete}>
      <h1>Module {index + 1}</h1>
      <h2>{module.title}</h2>
      <p>{module.description}</p>
      <S.Right>
        {complete ? (
          <TextButton text="Complete" glow disabled icon={<Complete />} />
        ) : (
          <TextButton
            text="Start"
            icon={<ArrowRight />}
            onClick={startModule}
          />
        )}
      </S.Right>
    </S.Module>
  );
};

const Modules: FC<{ completeModules: Array<string> }> = ({
  completeModules
}) => {
  const { modules } = useData();

  return (
    <Body>
      <Page>
        <PageBubbles />
        <Grid>
          <PageTitle>Select a module</PageTitle>
          <P large>Which HHD module are you here to complete?</P>
          <S.ModuleList>
            {modules.map((module: ModuleProps, i: number) => (
              <Module
                completeModules={completeModules}
                module={module}
                key={module.slug}
                index={i}
              />
            ))}
          </S.ModuleList>
        </Grid>
      </Page>
    </Body>
  );
};

export default connect(
  (state: RootState) => ({
    completeModules: completeModulesSelector(state)
  }),
  null
)(Modules);
