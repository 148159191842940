import React from 'react';
import SVG from 'src/components/svg/SVG';
const Mission = () => (
  <SVG width={72} height={73} viewBox="0 0 72 73">
    <defs>
      <filter
        id="vbj986biva"
        width="274.4%"
        height="220%"
        x="-87.2%"
        y="-60%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="12"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0.568627451 0 0 0 0 0.854901961 0 0 0 1 0"
        />
        <feOffset in="SourceAlpha" result="shadowOffsetOuter2" />
        <feGaussianBlur
          in="shadowOffsetOuter2"
          result="shadowBlurOuter2"
          stdDeviation="6"
        />
        <feColorMatrix
          in="shadowBlurOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 0 0 0 0 0 0.568627451 0 0 0 0 0.854901961 0 0 0 1 0"
        />
        <feOffset in="SourceAlpha" result="shadowOffsetOuter3" />
        <feGaussianBlur
          in="shadowOffsetOuter3"
          result="shadowBlurOuter3"
          stdDeviation="3"
        />
        <feColorMatrix
          in="shadowBlurOuter3"
          result="shadowMatrixOuter3"
          values="0 0 0 0 0 0 0 0 0 0.568627451 0 0 0 0 0.854901961 0 0 0 1 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
          <feMergeNode in="shadowMatrixOuter3" />
        </feMerge>
      </filter>
      <path
        id="9hfbkiyjnb"
        d="M35.645 6c11.402 0 20.646 9.44 20.646 21.085 0 11.972-12.73 30.346-18.198 37.685-.611.82-1.53 1.23-2.448 1.23-.918 0-1.836-.41-2.448-1.23C27.73 57.43 15 39.057 15 27.085 15 15.441 24.243 6 35.645 6zm0 2C25.365 8 17 16.562 17 27.085c0 11.368 12.45 29.305 17.801 36.49.276.37.648.425.844.425.197 0 .569-.055.844-.425 5.352-7.185 17.802-25.122 17.802-36.49C54.29 16.562 45.926 8 35.645 8zm.323 3.809c8.195 0 14.839 6.646 14.839 14.844s-6.644 14.844-14.84 14.844c-8.194 0-14.838-6.646-14.838-14.844S27.773 11.81 35.968 11.81zm0 2c-7.08 0-12.839 5.762-12.839 12.844s5.76 12.844 12.839 12.844c7.08 0 12.839-5.762 12.839-12.844s-5.76-12.844-12.84-12.844zm8.103 6.951c.403.378.423 1.011.046 1.414L34.44 32.5c-.186.198-.443.312-.714.316h-.016c-.265 0-.52-.105-.708-.292l-5.16-5.164c-.39-.39-.39-1.024 0-1.414.39-.39 1.023-.39 1.414 0l4.43 4.432 8.971-9.572c.377-.403 1.01-.424 1.414-.046z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(0 1)">
      <use fill="#000" filter="url(#vbj986biva)" xlinkHref="#9hfbkiyjnb" />
      <use fill="#FFF" xlinkHref="#9hfbkiyjnb" />
    </g>
  </SVG>
);

export default Mission;
