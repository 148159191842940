import { Redirect } from '@reach/router';
import React, { ComponentType, FC } from 'react';
import { useModuleSubmodule } from 'src/hooks/useData';
interface ModuleSubmoduleProps {
  moduleSlug: string;
  submoduleSlug: string;
}

export const withModuleSubmodule = <P extends object>(
  Component: ComponentType<P>
): FC<P & ModuleSubmoduleProps> => ({
  moduleSlug,
  submoduleSlug,
  ...props
}: ModuleSubmoduleProps) => {
  const {
    module,
    submodule
  }: {
    module: ModuleProps | undefined;
    submodule: SubModuleProps | undefined;
  } = useModuleSubmodule(moduleSlug, submoduleSlug);

  if (!module || !submodule) {
    return <Redirect to="/404/" noThrow />;
  }

  return <Component module={module} submodule={submodule} {...(props as P)} />;
};

export default withModuleSubmodule;
