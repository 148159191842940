import React, { FC, ReactNode } from 'react';
import Best from 'src/components/svg/questionnaire/Best';
import Good from 'src/components/svg/questionnaire/Good';
import Incorrect from 'src/components/svg/questionnaire/Incorrect';
import * as S from './styles';

export const Match: FC<ConvertedMatchProps> = ({
  children,
  visited,
  matchType,
  onClick,
  value
}) => {
  return (
    <S.MatchButton
      type="button"
      tabIndex={0}
      visited={visited}
      onClick={onClick}
      matchType={visited === true ? matchType : ''}
      value={value}
      data-type={matchType}
      data-visited={visited}
    >
      {children}
      {matchType === 'incorrect' && visited && <Incorrect />}
      {matchType === 'good' && visited && <Good />}
      {matchType === 'strong' && visited && <Best />}
    </S.MatchButton>
  );
};

export const Matches: FC<{ children: ReactNode }> = ({ children }) => {
  return <S.MatchesStyle>{children}</S.MatchesStyle>;
};
