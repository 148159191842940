/* eslint-disable camelcase */
/* eslint-disable dot-notation */

import { navigate } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ResponseModuleComplete } from 'src/components/Response';
import { PageBubbles } from 'src/components/layout/Bubbles';
import { Body, Grid } from 'src/components/layout/Wrap';
import { withModule } from 'src/hoc/withModule';
import { useMappedAnswers, useModuleComplete } from 'src/hooks/useData';
import { RootState } from 'src/state/reducers';
import {
  answersSelector,
  completeModulesSelector,
  userEmailSelector,
  userNameSelector
} from 'src/state/selectors';
import { PageQuestion } from 'src/styles/Layout';
import dataLayerPush from 'src/utils/dataLayerPush';

// Collect the aws endpoint from the current process, this should be set on CI.
const awsEndpoint = process.env.AWS_EMAIL_ENDPOINT
  ? process.env.AWS_EMAIL_ENDPOINT
  : 'https://d8dcg8pu61.execute-api.us-east-1.amazonaws.com/staging/';

const emailTemplateNamespace = process.env.GATSBY_ACTIVE_ENV
  ? process.env.GATSBY_ACTIVE_ENV
  : 'staging';

const ModuleComplete: FC<{
  module: ModuleProps;
  email: string;
  completeModules: Array<string>;
  name: string;
  answers: Array<AnswerProps>;
}> = ({ module, email, answers, completeModules, name }) => {
  const [loading, setLoading] = useState(true);

  const complete = useModuleComplete(module, completeModules);

  const mappedAnswers = useMappedAnswers(answers, module.slug);
  const questions = mappedAnswers.questions;
  const matches = mappedAnswers.matches;

  useEffect(() => {
    if (complete === false) {
      navigate('/', { replace: true });

      return null;
    }
    const variablesToSend: any = {
      Recipients: [email],
      TemplateData: { first_name: name }
    };

    if (module.slug === 'action-areas') {
      variablesToSend['TemplateName'] = `${emailTemplateNamespace}-module-1`;
      console.log(matches);
      matches.forEach((ques, i) => {
        variablesToSend['TemplateData'][`question_${i + 1}_title`] =
          ques?.title;
        variablesToSend['TemplateData'][`question_${i + 1}_match`] =
          ques?.selectedMatch;
        variablesToSend['TemplateData'][`question_${i + 1}_answer`] =
          ques?.selectedAnswer;
        variablesToSend['TemplateData'][`question_${i + 1}_suggested_match`] =
          ques?.strongestMatch;
        variablesToSend['TemplateData'][`question_${i + 1}_suggested_answer`] =
          ques?.strongestAnswer;
      });
    }

    if (module.slug === 'health-promotion-strategies') {
      variablesToSend['TemplateName'] = `${emailTemplateNamespace}-module-2`;
      questions.forEach((ques, i) => {
        variablesToSend['TemplateData'][`question_${i + 1}_title`] = ques.title;
        variablesToSend['TemplateData'][`question_${i + 1}_question`] =
          ques.explainQuestion;
        variablesToSend['TemplateData'][`question_${i + 1}_answer`] =
          ques.answer;
        variablesToSend['TemplateData'][`question_${i + 1}_suggested_answer`] =
          ques.suggestedAnswer;
      });
    }

    if (module.slug === 'sustainable-development-goals') {
      variablesToSend['TemplateName'] = `${emailTemplateNamespace}-module-3`;
      questions.forEach((ques, i) => {
        variablesToSend['TemplateData'][`question_${i + 1}_title`] = ques.title;
        variablesToSend['TemplateData'][`question_${i + 1}_question`] =
          ques.explainQuestion;
        variablesToSend['TemplateData'][`question_${i + 1}_answer`] =
          ques.answer;
        variablesToSend['TemplateData'][`question_${i + 1}_suggested_answer`] =
          ques.suggestedAnswer;
      });
    }
    fetch(`${awsEndpoint}module-email`, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify(variablesToSend)
    })
      .then(response => response.json())
      .then(data => {
        setLoading(false);
        console.log(data);
      })
      .catch(error => {
        setLoading(false);
        console.log('Request failed', error);
        // eslint-disable-next-line no-alert
        window.alert(
          'Sorry, something went wrong. Please refresh and we will try sending your email again.'
        );
      });

    dataLayerPush({
      event: 'trackEvent',
      eventCategory: 'Module Progression',
      eventAction: 'Finish',
      eventLabel: module.title
    });

    return null;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Body module={module.title}>
      <PageBubbles />
      <PageQuestion>
        <Grid>
          <ResponseModuleComplete
            completeContent={`You've completed Module ${module.id}!`}
            email={email}
            appHome="/app"
            sending={loading}
          />
        </Grid>
      </PageQuestion>
    </Body>
  );
};

export default connect((state: RootState) => ({
  email: userEmailSelector(state),
  completeModules: completeModulesSelector(state),
  answers: answersSelector(state),
  name: userNameSelector(state)
}))(withModule(ModuleComplete));
