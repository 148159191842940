import React from 'react';
import SVG from 'src/components/svg/SVG';
const Good = () => (
  <SVG width={24} height={25} viewBox="0 0 24 25">
    <path
      fill="#FFF"
      d="M12 3.25c5.385 0 9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75S2.25 18.385 2.25 13 6.615 3.25 12 3.25zm0 1.5c-4.556 0-8.25 3.694-8.25 8.25s3.694 8.25 8.25 8.25 8.25-3.694 8.25-8.25S16.556 4.75 12 4.75zm3.53 5.72c.27.27.291.695.063.99l-.063.07-4 4c-.27.27-.695.291-.99.063l-.07-.063-2-2c-.293-.293-.293-.767 0-1.06.27-.27.695-.291.99-.063l.07.063L11 13.939l3.47-3.47c.293-.292.767-.292 1.06 0z"
    />
  </SVG>
);

export default Good;
