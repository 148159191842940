import { Redirect } from '@reach/router';
import React, { ComponentType, FC } from 'react';
import { useModule } from 'src/hooks/useData';
interface ModuleSubmoduleProps {
  moduleSlug: string;
}
export const withModule = <P extends object>(
  Component: ComponentType<P>
): FC<P & ModuleSubmoduleProps> => ({
  moduleSlug,
  ...props
}: ModuleSubmoduleProps) => {
  const module: ModuleProps | undefined = useModule(moduleSlug);

  if (!module) {
    return <Redirect to="/404/" noThrow />;
  }

  return <Component module={module} {...(props as P)} />;
};

export default withModule;
