// import { Link } from 'gatsby';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import Response from 'src/components/Response';
import { TopBar } from 'src/components/Splash';
import { Body, Grid } from 'src/components/layout/Wrap';
import { withModuleSubmodule } from 'src/hoc/withModuleSubmodule';
import { useSubmoduleComplete } from 'src/hooks/useData';
import { RootState } from 'src/state/reducers';
import { answersSelector } from 'src/state/selectors';
import { PageQuestion } from 'src/styles/Layout';

const MatchResponse: FC<{
  module: ModuleProps;
  matchType: string;
  submodule: SubModuleProps;
  navigate: Function;
  answers: Array<AnswerProps>;
}> = ({ module, submodule, navigate, answers, matchType }) => {
  const submoduleComplete: boolean = useSubmoduleComplete(
    module,
    submodule,
    answers
  );

  if (submoduleComplete === true) {
    navigate(`/app/module/${module.slug}/${submodule.slug}/complete`);

    return null;
  }

  const backLink = `/app/module/${module.slug}/${submodule.slug}/questionnaire`;

  return (
    <Body module={submodule.title} experience backLink>
      <TopBar>
        <span style={{ width: '50%' }} />
      </TopBar>

      <PageQuestion>
        <Grid>
          <Response
            type={matchType}
            match={submodule.title}
            goToExplain={`/app/module/${module.slug}/${submodule.slug}/questionnaire/explain`}
            backToMatch={backLink}
          />
        </Grid>
      </PageQuestion>
    </Body>
  );
};

export default connect(
  (state: RootState) => ({ answers: answersSelector(state) }),
  null
)(withModuleSubmodule(MatchResponse));
