import React from 'react';
import SVG from './SVG';
const Info = () => (
  <SVG width={24} height={25} viewBox="0 0 24 24">
    <path
      fill="#C8C8C8"
      d="M12 22.75c5.385 0 9.75-4.365 9.75-9.75S17.385 3.25 12 3.25 2.25 7.615 2.25 13s4.365 9.75 9.75 9.75zm0-1.5c-4.556 0-8.25-3.694-8.25-8.25S7.444 4.75 12 4.75s8.25 3.694 8.25 8.25-3.694 8.25-8.25 8.25zm.01-11.5c.414 0 .75-.336.75-.75 0-.385-.29-.702-.663-.745L12 8.25c-.414 0-.75.336-.75.75 0 .385.29.702.663.745l.097.005zM12 17c.385 0 .702-.29.745-.663l.005-.087v-4c0-.414-.336-.75-.75-.75-.385 0-.702.29-.745.663l-.005.087v4c0 .414.336.75.75.75z"
    />
  </SVG>
);

export default Info;
