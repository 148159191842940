import { navigate } from 'gatsby';
import React, { FC, ReactNode } from 'react';
import { connect } from 'react-redux';
import { loggedInSelector, userEmailSelector } from '../state/selectors';
import dataLayerPush from '../utils/dataLayerPush';

const PrivateRoute: FC<{
  path: string;
  component: any;
  location?: {
    pathname?: string;
  };
  email?: string;
  loggedIn?: boolean;
  children?: ReactNode;
}> = ({ component: Component, location, loggedIn, email, ...rest }) => {
  if (!loggedIn && location?.pathname !== `/`) {
    navigate('/');

    return null;
  }

  dataLayerPush({ user_id: email }); // eslint-disable-line camelcase

  return <Component {...rest} />;
};

export default connect(
  state => ({
    loggedIn: loggedInSelector(state),
    email: userEmailSelector(state)
  }),
  null
)(PrivateRoute);
