import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Body, Grid } from 'src/components/layout/Wrap';
import { withModuleSubmodule } from 'src/hoc/withModuleSubmodule';
import { RootState } from 'src/state/reducers';
import { userNameSelector } from 'src/state/selectors';
import { PageQuestion } from 'src/styles/Layout';
import { ResponseSubComplete } from '../../Response';
const SubmoduleComplete: FC<{
  module: ModuleProps;
  submodule: SubModuleProps;
  username: string;
}> = ({ module, submodule, username }) => {
  return (
    <Body module={submodule.title} experience>
      <PageQuestion>
        <Grid>
          <ResponseSubComplete
            completeTitle={
              module.type === 'matchAndExplain'
                ? 'Experience complete!'
                : 'Complete!'
            }
            moduleHome={`/app/module/${module.slug}`}
            completeContent={
              module.type === 'matchAndExplain'
                ? `Top effort, ${username}! You have completed the ${submodule.title} experience.`
                : `Top effort, ${username}! You've completed ${submodule.title}.`
            }
          />
        </Grid>
      </PageQuestion>
    </Body>
  );
};

export default connect((state: RootState) => ({
  username: userNameSelector(state)
}))(withModuleSubmodule(SubmoduleComplete));
