import Query from 'src/utils/Queries';
import tw, { styled } from 'twin.macro';

export const WelcomePage = styled.div`
  ${tw`
    flex
    flex-auto
  `}
  margin: 24px 0 63px;
  ${Query.tablet} {
    margin: 56px 0 80px;
  }
`;
