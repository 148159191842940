import Query from 'src/utils/Queries';
import tw, { css, styled } from 'twin.macro';
interface ModuleProps {
  complete?: boolean;
}

export const Module = styled.article<ModuleProps>`
  ${tw`
    rounded
    border
    border-blue
    border-solid
    block
    pl-4
    pt-4
    bg-black
  `};
  margin-bottom: 24px;
  h1,
  h2,
  p {
    padding-right: 16px;
  }
  h1 {
    ${tw`
      uppercase
      text-blue
      mb-1
    `}
    font-size: 15px;
    text-shadow: none;
  }
  h2 {
    ${tw`
      uppercase
      text-blue
      mb-2
    `}
  }
  p {
    margin: 0;
    line-height: 1.5;
  }
  ${({ complete }) =>
    complete &&
    css`
      ${tw`
        border-grey
      `};
      h1,
      h2,
      p {
        ${tw`
          text-grey
      `}
      }
    `}
  &:last-child {
    margin-bottom: 0;
  }
`;

export const ModuleList = styled.section`
  ${tw`
    mt-2
  `}
  ${Query.tablet} {
    ${tw`
    mt-4
    `}
  }
`;

export const Right = styled.aside`
  text-align: right;
  p {
    ${tw`
        font-heading
      `};
    padding: 16px;
  }
`;
