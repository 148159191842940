import React, { FC } from 'react';
import withModuleTutorial from 'src/hoc/withModuleTutorial';
import Slides from './Slides';
const Tutorial: FC<{
  module: ModuleProps;
  tutorial: TutorialProps;
}> = ({ module, tutorial }) => (
  <Slides
    slides={tutorial.steps}
    currentModule={module}
    moduleSlug={module.slug}
  />
);

export default withModuleTutorial(Tutorial);
