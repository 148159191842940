import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
// import { useMeasure } from 'react-use';
import ResizeObserver from 'resize-observer-polyfill';
import styled from 'styled-components';
const AnimatedWrapper = styled(animated.div)<{ style: any }>`
  height: 0;
  opacity: 0;
  overflow: visible;
`;

export const useMeasure = () => {
  const ref = useRef();
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 });
  const [ro] = useState(
    () => new ResizeObserver(([entry]) => set(entry.contentRect))
  );

  useEffect(() => {
    const newRef = ref;

    if (newRef.current) {
      // @ts-ignore
      ro.observe(newRef.current);
    }

    return () => {
      // @ts-ignore
      ro.unobserve(newRef.current);
    };
  }, [ref, ro]);

  return [{ ref }, bounds];
};

const AnimatedHeight: FC<{
  heightCondition: boolean;
  children: ReactNode;
}> = props => {
  const { heightCondition, children } = props;
  // We need to store the actual dom element in order to track its height.
  const [bind, { height }]: any = useMeasure();

  const styleProps = useSpring({
    visibility: heightCondition ? 'visible' : 'hidden',
    height: heightCondition ? height : 0,
    opacity: heightCondition ? 1 : 0
  });

  return (
    <AnimatedWrapper style={styleProps}>
      <div {...bind} style={{ overflow: 'auto' }}>
        {children}
      </div>
    </AnimatedWrapper>
  );
};

export default AnimatedHeight;
