import { useField, useFormikContext } from 'formik';
import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  HelpText,
  InputGroup,
  Label,
  baseInputStyles
} from 'src/components/form/styles';
import tw, { styled } from 'twin.macro';
interface TextareaProps {
  error?: boolean;
  hasIcon?: boolean;
}
export const Textarea = styled('textarea')<TextareaProps>`
  ${baseInputStyles}

  ${tw`
    w-full
    resize-none
  `};
  min-height: 210px;
  resize: none;
  display: block;
`;

export const TextArea: FC<{
  id?: string;
  label?: string;
  name: string;
  placeholder?: string;
  wordLimit?: number;
  required?: boolean;
}> = ({ id, label, name, wordLimit, required = false }) => {
  const [words, setWords] = useState(0);
  const [field, meta, helpers] = useField(name);
  const { submitCount } = useFormikContext();
  const error = meta.error;
  const submitAttempted = submitCount > 0;
  const outputError = error && submitAttempted;

  const handleChange = useCallback(
    e => {
      helpers.setValue(e.target.value);

      if (wordLimit) {
        let value = e.target.value;
        const initial = meta.value.slice(0, -1);

        value = value
          .replace(/(^\s*)|(\s*$)/giu, '')
          .replace(/[ ]{2,}/giu, ' ')
          .replace(/\n /u, '\n');
        const wordCount = value.split(' ').length;

        if (wordCount <= wordLimit) {
          helpers.setValue(e.target.value);
        } else {
          helpers.setValue(initial);
        }
        if (e.target.value.length === 0) {
          setWords(0);
        } else if (wordCount <= wordLimit) {
          setWords(wordCount);
        } else {
          setWords(wordCount - 1);
        }
      } else {
        helpers.setValue(e.target.value);
      }
    },
    [meta.value, wordLimit, helpers, setWords]
  );

  useEffect(() => {
    const wordCount = meta.value.split(' ').length;

    if (wordLimit) {
      if (wordCount <= wordLimit) {
        setWords(wordCount);
      } else {
        setWords(wordCount - 1);
      }
    }
  }, [meta.value, wordLimit]);

  return (
    <InputGroup>
      {label && <Label htmlFor={id}>{label}</Label>}
      <Textarea
        id={id}
        autoComplete="off"
        required={required}
        name={name}
        onBlur={field.onBlur} // eslint-disable-line react/jsx-handler-names
        error={!!outputError}
        value={meta.value}
        onChange={handleChange}
        aria-invalid={!!(outputError && required)}
      />
      {wordLimit && (
        <HelpText>
          {words}/{wordLimit} suggested words
        </HelpText>
      )}
      {outputError && <HelpText error>{error}</HelpText>}
    </InputGroup>
  );
};

export default TextArea;
