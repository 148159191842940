import React from 'react';
import SVG from './SVG';
const Complete = () => (
  <SVG width={24} height={24} viewBox="0 0 24 24">
    <defs>
      <filter
        id="jm41dqim0a"
        width="469.2%"
        height="469.2%"
        x="-184.6%"
        y="-184.6%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="12"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0.568627451 0 0 0 0 0.854901961 0 0 0 1 0"
        />
        <feOffset in="SourceAlpha" result="shadowOffsetOuter2" />
        <feGaussianBlur
          in="shadowOffsetOuter2"
          result="shadowBlurOuter2"
          stdDeviation="6"
        />
        <feColorMatrix
          in="shadowBlurOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 0 0 0 0 0 0.568627451 0 0 0 0 0.854901961 0 0 0 1 0"
        />
        <feOffset in="SourceAlpha" result="shadowOffsetOuter3" />
        <feGaussianBlur
          in="shadowOffsetOuter3"
          result="shadowBlurOuter3"
          stdDeviation="3"
        />
        <feColorMatrix
          in="shadowBlurOuter3"
          result="shadowMatrixOuter3"
          values="0 0 0 0 0 0 0 0 0 0.568627451 0 0 0 0 0.854901961 0 0 0 1 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
          <feMergeNode in="shadowMatrixOuter3" />
        </feMerge>
      </filter>
      <path
        id="gwbmhdzbfb"
        d="M12 2.25c5.385 0 9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12 6.615 2.25 12 2.25zm0 1.5c-4.556 0-8.25 3.694-8.25 8.25s3.694 8.25 8.25 8.25 8.25-3.694 8.25-8.25S16.556 3.75 12 3.75zm3.53 5.72c.27.27.291.695.063.99l-.063.07-4 4c-.27.27-.695.291-.99.063l-.07-.063-2-2c-.293-.293-.293-.767 0-1.06.27-.27.695-.291.99-.063l.07.063L11 12.939l3.47-3.47c.293-.292.767-.292 1.06 0z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <use fill="#000" filter="url(#jm41dqim0a)" xlinkHref="#gwbmhdzbfb" />
      <use fill="#FFF" xlinkHref="#gwbmhdzbfb" />
    </g>
  </SVG>
);

export default Complete;
