import Query from 'src/utils/Queries';
import tw, { css, styled } from 'twin.macro';
interface SlideButtonProps {
  current?: boolean;
}

export const SlidesWrap = styled.div`
  ${tw`mt-8 relative text-center flex flex-col flex-auto`};
  max-height: 420px;
  ${Query.tablet} {
    margin-top: 188px;
    max-height: 640px;
  }
`;

export const SlidesNav = styled.ul`
  ${tw`m-0 p-0 list-none`};
  margin-bottom: 66px;
`;
export const SlidesNavItem = styled.li`
  display: inline-block;
  margin: 0 8px;
`;

export const SlideButton = styled.button<SlideButtonProps>`
  ${tw`
  relative
  rounded-full
  border
  border-blue
  border-solid
  block
`};
  height: 16px;
  width: 16px;
  &:active,
  &:focus {
    outline: 0;
  }
  &:after {
    background: transparent;
    transition: 0.2s all;
    border-radius: 6px;
    width: 6px;
    height: 6px;
    content: ' ';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  ${({ current }) =>
    current &&
    css`
      &:after {
        ${tw`
      shadow-blue
    `};
        background: white;
      }
    `}
`;

export const Slide = styled.div`
  ${tw`text-center`};
  p {
    min-height: 96px;
    margin-bottom: 24px;
  }
  svg {
    overflow: visible;
  }
  h1 {
    margin-top: 16px;
  }
  &:hover {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
  }
  ${Query.tablet} {
    p {
      min-height: 76px;
    }
  }
`;
export const Skip = styled.p`
  ${tw`text-center`};
  margin-top: auto;
`;
export const RingWrap = styled.div`
  ${tw`absolute`};
  left: 50%;
  top: -120px;
  transform: translate(-50%, 0);
  svg {
    width: 640px;
    height: 640px;
    overflow: visible;
  }
  ${Query.tablet} {
    top: -33px;
    svg {
      width: 756px;
      height: 756px;
    }
  }
`;
export const Finish = styled.div`
  ${tw`text-center`};
  margin-top: auto;
  ${Query.tablet} {
    margin-top: auto;
  }
`;
