import React from 'react';
import SVG from './SVG';
const Tick = () => (
  <SVG width={32} height={33} viewBox="0 0 32 33">
    <path
      fill="#000"
      d="M16 3c7.18 0 13 5.82 13 13s-5.82 13-13 13S3 23.18 3 16 8.82 3 16 3zm0 2C9.925 5 5 9.925 5 16s4.925 11 11 11 11-4.925 11-11S22.075 5 16 5zm4.707 7.626c.36.36.388.928.083 1.32l-.083.094-5.333 5.334c-.36.36-.928.388-1.32.083l-.094-.083-2.667-2.667c-.39-.39-.39-1.024 0-1.414.36-.36.928-.388 1.32-.083l.094.083 1.959 1.959 4.627-4.626c.39-.39 1.024-.39 1.414 0z"
    />
  </SVG>
);

export default Tick;
