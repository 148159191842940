import { Redirect } from '@reach/router';
import React, { ComponentType, FC } from 'react';
import { useModuleTutorial } from 'src/hooks/useData';
interface ModuleSubmoduleProps {
  moduleSlug: string;
}

export const withModuleTutorial = <P extends object>(
  Component: ComponentType<P>
): FC<P & ModuleSubmoduleProps> => ({
  moduleSlug,
  ...props
}: ModuleSubmoduleProps) => {
  const {
    module,
    tutorial
  }: {
    module: ModuleProps | undefined;
    tutorial: TutorialProps | undefined;
  } = useModuleTutorial(moduleSlug);

  if (!module || !tutorial) {
    return <Redirect to="/404/" noThrow />;
  }

  return <Component module={module} tutorial={tutorial} {...(props as P)} />;
};

export default withModuleTutorial;
