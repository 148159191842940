/* eslint react/jsx-no-useless-fragment: 0 */
import { Link, navigate } from 'gatsby';
import React, { FC, useCallback } from 'react';
import { connect } from 'react-redux';
import Button from 'src/components/Button';
// import { SpringLink, SpringTransition } from 'src/components/TransitionLink';
import { Body, Grid } from 'src/components/layout/Wrap';
import Tick from 'src/components/svg/Tick';
import withModule from 'src/hoc/withModule';
import {
  useAllSubmodulesComplete,
  useSubmoduleComplete
} from 'src/hooks/useData';
import { RootState } from 'src/state/reducers';
import { completeModule } from 'src/state/reducers/answers';
import { answersSelector } from 'src/state/selectors';
import { Page } from 'src/styles/Layout';
import { ModuleHeader } from 'src/styles/Layout';
import { P, PageTitle } from 'src/styles/Typography';
import Navigation from './Navigation';
import * as S from './styles';

const Experience: FC<{
  module: ModuleProps;
  index: number;
  submodule: SubModuleProps;
  answers: Array<AnswerProps>;
}> = ({ module, submodule, answers, index }) => {
  const submoduleComplete: boolean = useSubmoduleComplete(
    module,
    submodule,
    answers
  );

  if (submoduleComplete) {
    return (
      <S.Experience complete>
        <S.ExperienceImage complete>
          <Tick />
        </S.ExperienceImage>
        <S.ExperienceBody complete>
          <S.ExperienceTitle complete>{submodule.title}</S.ExperienceTitle>
          {submodule.subtitle && <p>{submodule.subtitle}</p>}
        </S.ExperienceBody>
      </S.Experience>
    );
  }

  return (
    <S.Experience key={submodule.slug}>
      <Link
        to={`/app/module/${module.slug}/${submodule.slug}/${
          module.type === 'matchAndExplain' ? 'welcome' : 'questionnaire'
        }`}
      >
        <S.ExperienceImage>
          {submodule.tileImage !== '' ? (
            <img
              src={`/images/${submodule.tileImage}`}
              alt={submodule.title}
              width="72"
              height="72"
            />
          ) : (
            <span>
              {submodule.number !== '' ? submodule.number : index + 1}
            </span>
          )}
        </S.ExperienceImage>
        <S.ExperienceBody complete={false}>
          <S.ExperienceTitle complete={false}>
            {submodule.title}
          </S.ExperienceTitle>
          {submodule.subtitle && <p>{submodule.subtitle}</p>}
        </S.ExperienceBody>
      </Link>
    </S.Experience>
  );
};

const Module: FC<{
  module: ModuleProps;
  answers: Array<AnswerProps>;
  completeModuleAction: Function;
}> = ({ module, answers, completeModuleAction }) => {
  const moduleComplete = useAllSubmodulesComplete(module, answers);

  const triggerCompleteModule = useCallback(() => {
    completeModuleAction(module.id);
    navigate(`/app/module/${module.slug}/complete`);
  }, [completeModuleAction, module.id, module.slug]);

  return (
    <Body module={module.title}>
      <ModuleHeader>
        <S.ExperienceHeader
          src={`/images/${module.headerImage}`}
          alt="Module header images"
          width="810"
          height="200"
        />
      </ModuleHeader>

      <Page medium>
        <Grid>
          <PageTitle>{module.selectTitle}</PageTitle>
          <P large>{module.selectSubtitle}</P>
          <S.ExperienceList>
            {module.groups.map((group: any, i: number) => (
              <Experience
                key={group.slug}
                index={i}
                module={module}
                submodule={group}
                answers={answers}
              />
            ))}
          </S.ExperienceList>
        </Grid>
        {moduleComplete ? (
          <S.ExperienceComplete>
            <Button glow="orange" onClick={triggerCompleteModule}>
              Proceed to next step
            </Button>
          </S.ExperienceComplete>
        ) : (
          <Navigation instructions={`/app/module/${module.slug}/tutorial`} />
        )}
      </Page>
    </Body>
  );
};
const mapDispatchToProps = {
  completeModuleAction: completeModule
};

export default connect((state: RootState) => {
  return {
    answers: answersSelector(state)
  };
}, mapDispatchToProps)(withModule(Module));
