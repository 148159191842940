import gsap from 'gsap';
import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { styled } from 'twin.macro';

const Rings = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
`;
const OuterRing = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  &:after,
  &:before {
    border-radius: 1000px;
    width: 100%;
    position: absolute;
    content: ' ';
    border: 2px solid white;
    left: 0;
    top: 0;
    bottom: 0;
    display: block;
    box-shadow: 0 0 8px #f8971d, inset 0 0 8px #f8971d;
    transform: rotate(30deg);
  }
  &:before {
    clip-path: inset(-5% calc(50% + 5%) -5% -5%);
  }
  &:after {
    left: auto;
    right: 0;
    clip-path: inset(-5% -5% -5% calc(50% + 5%));
  }
`;
const InnerRing = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 90%;
  height: 90%;
  transform-origin: 50% 50%;
  transform: translate(-50%, -50%) rotate(-30deg);
  display: block;
  &:after,
  &:before {
    border-radius: 1000px;
    width: 100%;
    position: absolute;
    content: ' ';
    border: 2px solid white;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: block;
    box-shadow: 0 0 8px #0091da, inset 0 0 8px #0091da;
  }
  &:before {
    clip-path: inset(-5% calc(50% + 5%) -5% -5%);
  }
  &:after {
    left: auto;
    right: 0;
    clip-path: inset(-5% -5% -5% calc(50% + 5%));
  }
`;
const GlowingRings = () => {
  const animateOuter = useRef(null);
  const animateGroup = useRef(null);
  const animateInner = useRef(null);
  const [visible, setVisible] = useState('none');

  useEffect(() => {
    setVisible('block');
    const tl = gsap.timeline();

    tl.fromTo(
      animateGroup.current,
      { transformOrigin: '50% 50%', scale: 0, opacity: 0 },
      { scale: 1, duration: 1, opacity: 1 }
    );
    gsap.to(animateOuter.current, {
      rotation: '360deg',
      ease: 'linear',
      duration: 30,
      transformOrigin: '50% 50%',
      repeat: -1
    });
    gsap.to(animateInner.current, {
      rotation: '-360deg',
      ease: 'linear',
      duration: 20,
      transformOrigin: '50% 50%',
      repeat: -1
    });
  }, []);

  return (
    <Rings ref={animateGroup} style={{ display: visible }}>
      <InnerRing ref={animateOuter} />
      <OuterRing ref={animateInner} />
    </Rings>
  );
};

export default GlowingRings;
