import React from 'react';
import SVG from './SVG';
const Pin = () => (
  <SVG width={24} height={25} viewBox="0 0 24 24">
    <path
      fill="#F8971D"
      d="M5.813 5.813c3.417-3.417 8.957-3.417 12.374 0s3.417 8.957 0 12.374l-4.242 4.243c-.516.516-1.215.806-1.944.806-.73 0-1.43-.29-1.944-.806l-4.244-4.243c-3.417-3.417-3.417-8.957 0-12.374zm11.313 1.06c-2.83-2.83-7.421-2.83-10.252 0-2.832 2.832-2.832 7.422 0 10.254l4.244 4.243c.234.234.551.366.883.366.33 0 .648-.132.883-.366l4.243-4.243c2.83-2.832 2.83-7.422 0-10.253zM12 8.25c2.071 0 3.75 1.679 3.75 3.75 0 2.071-1.679 3.75-3.75 3.75-2.071 0-3.75-1.679-3.75-3.75 0-2.071 1.679-3.75 3.75-3.75zm0 1.5c-1.243 0-2.25 1.007-2.25 2.25s1.007 2.25 2.25 2.25 2.25-1.007 2.25-2.25S13.243 9.75 12 9.75z"
    />
  </SVG>
);

export default Pin;
