import React from 'react';
import SVG from 'src/components/svg/SVG';
const Select = () => (
  <SVG width={72} height={73} viewBox="0 0 72 73">
    <defs>
      <filter
        id="4f1t09eb2a"
        width="257.4%"
        height="204.3%"
        x="-78.7%"
        y="-52.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="12"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0.568627451 0 0 0 0 0.854901961 0 0 0 1 0"
        />
        <feOffset in="SourceAlpha" result="shadowOffsetOuter2" />
        <feGaussianBlur
          in="shadowOffsetOuter2"
          result="shadowBlurOuter2"
          stdDeviation="6"
        />
        <feColorMatrix
          in="shadowBlurOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 0 0 0 0 0 0.568627451 0 0 0 0 0.854901961 0 0 0 1 0"
        />
        <feOffset in="SourceAlpha" result="shadowOffsetOuter3" />
        <feGaussianBlur
          in="shadowOffsetOuter3"
          result="shadowBlurOuter3"
          stdDeviation="3"
        />
        <feColorMatrix
          in="shadowBlurOuter3"
          result="shadowMatrixOuter3"
          values="0 0 0 0 0 0 0 0 0 0.568627451 0 0 0 0 0.854901961 0 0 0 1 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
          <feMergeNode in="shadowMatrixOuter3" />
        </feMerge>
      </filter>
      <path
        id="2f3posc6ab"
        d="M32.625 15.75c2.688 0 4.875 2.187 4.875 4.875v9.774c.759-.488 1.658-.774 2.625-.774 1.598 0 3.016.777 3.905 1.969.892-.977 2.172-1.594 3.595-1.594 1.962 0 3.653 1.168 4.425 2.843.702-.382 1.494-.593 2.325-.593 2.688 0 4.875 2.187 4.875 4.875V54.75c0 10.14-11.309 15.75-19.125 15.75-10.371 0-14.958-6.776-17.506-11.872-1.801-3.601-6.12-12.406-6.164-12.494-1.166-2.433-.14-5.35 2.285-6.512 2.404-1.153 5.294-.153 6.474 2.23l2.536 4.081V20.625c0-2.688 2.187-4.875 4.875-4.875zm0 2.25C31.177 18 30 19.177 30 20.625v29.25c0 .502-.333.944-.816 1.081-.483.138-1-.06-1.265-.487l-4.646-7.478c-.021-.035-.041-.07-.059-.108-.625-1.305-2.196-1.857-3.502-1.233-1.305.626-1.858 2.197-1.233 3.503.038.076 4.063 8.278 5.958 12.079l.194.39c2.475 4.948 6.471 10.628 15.494 10.628C47.022 68.25 57 63.443 57 54.75V37.125c0-1.447-1.178-2.625-2.625-2.625-.722 0-1.382.301-1.875.812V43.5c0 .622-.503 1.125-1.125 1.125s-1.125-.503-1.125-1.125v-8.625c0-1.447-1.178-2.625-2.625-2.625S45 33.428 45 34.875V43.5c0 .622-.503 1.125-1.125 1.125s-1.125-.503-1.125-1.125v-9c0-1.447-1.178-2.625-2.625-2.625S37.5 33.053 37.5 34.5v8.625c0 .622-.503 1.125-1.125 1.125s-1.125-.503-1.125-1.125v-22.5c0-1.448-1.178-2.625-2.625-2.625zm17.25 1.5c.622 0 1.125.503 1.125 1.125s-.503 1.125-1.125 1.125h-8.25c-.622 0-1.125-.503-1.125-1.125s.503-1.125 1.125-1.125zm-27 0c.622 0 1.125.503 1.125 1.125s-.503 1.125-1.125 1.125h-8.25c-.622 0-1.125-.503-1.125-1.125s.503-1.125 1.125-1.125zM43.121 8.54c.46-.417 1.172-.38 1.588.081.417.46.381 1.172-.08 1.589l-3.886 3.511c-.216.195-.486.29-.754.29-.308 0-.613-.125-.835-.37-.417-.461-.381-1.173.08-1.59zm-23.33.081c.415-.462 1.127-.498 1.588-.08l3.887 3.51c.46.418.497 1.129.08 1.59-.222.246-.527.37-.835.37-.269 0-.538-.096-.753-.29L19.87 10.21c-.461-.417-.497-1.128-.08-1.589zM32.624 1.5c.622 0 1.125.503 1.125 1.125v8.25c0 .622-.503 1.125-1.125 1.125s-1.125-.503-1.125-1.125v-8.25c0-.622.503-1.125 1.125-1.125z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(0 1)">
      <use fill="#000" filter="url(#4f1t09eb2a)" xlinkHref="#2f3posc6ab" />
      <use fill="#FFF" xlinkHref="#2f3posc6ab" />
    </g>
  </SVG>
);

export default Select;
