// import { Link } from 'gatsby';
import { useMatch } from '@reach/router';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Body } from 'src/components/layout/Wrap';
import { withModuleSubmodule } from 'src/hoc/withModuleSubmodule';
// import { useSubmoduleComplete } from 'src/hooks/useData';
import { RootState } from 'src/state/reducers';
import { answersSelector } from 'src/state/selectors';
import Explain from './types/Explain';
import MatchExplain from './types/MatchExplain';

// import QA from './types/QA';
// import Strategy from './types/Strategy';

const Questionnaire: FC<{
  module: ModuleProps;
  submodule: SubModuleProps;
  // answers: Array<AnswerProps>;
}> = ({ module, submodule }) => {
  // const submoduleComplete: boolean = useSubmoduleComplete(
  //   module,
  //   submodule,
  //   answers
  // );
  const match =
    useMatch(`/app/module/${module.slug}/${submodule.slug}/questionnaire/*`) ||
    false;

  const uri = match ? match['*'] : '';

  // to-do: Use module type to show the right kind of q. (matchAndExplain, Explain)
  const component =
    module.type === 'matchAndExplain' ? (
      <MatchExplain module={module} submodule={submodule} path={uri} />
    ) : (
      <Explain module={module} submodule={submodule} />
    );

  return (
    <Body module={submodule.title} experience backLink>
      {component}
    </Body>
  );
};

export default connect(
  (state: RootState) => ({ answers: answersSelector(state) }),
  null
)(withModuleSubmodule(Questionnaire));
