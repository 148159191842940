import { Link } from 'gatsby';
import React, { FC } from 'react';
import Button from 'src/components/Button';
import CheckIconButton from 'src/components/CheckIconButton';
import LinkButton from 'src/components/LinkButton';
import GlowingRings from 'src/components/animations/GlowingRings';
import Clap from 'src/components/svg/Clap';
import Finish from 'src/components/svg/Finish';
import Celebrate from 'src/components/svg/questionnaire/Celebrate';
import Thumbs from 'src/components/svg/questionnaire/Thumbs';
import { P, PageTitle } from 'src/styles/Typography';
import * as S from './styles';
export const ResponseGood: FC<{ backToMatch: string; goToExplain: string }> = ({
  backToMatch,
  goToExplain
}) => {
  return (
    <S.ResponseWrap>
      <p>
        <Thumbs />
      </p>
      <PageTitle>Good Match</PageTitle>
      <p>You made a good match, but not the best. Do you want to try again?</p>
      <S.ResponseFooter>
        <P large>
          <Button as={Link} to={backToMatch}>
            Try for best match
          </Button>
        </P>
        <LinkButton as={Link} to={goToExplain}>
          No, move to next question
        </LinkButton>
      </S.ResponseFooter>
    </S.ResponseWrap>
  );
};

export const ResponseStrong: FC<{ match: string; goToExplain: string }> = ({
  match,
  goToExplain
}) => {
  return (
    <S.ResponseWrap>
      <S.RingWrap>
        <GlowingRings />
      </S.RingWrap>
      <p>
        <Celebrate />
      </p>
      <PageTitle>Best match!</PageTitle>
      <p>
        Great work! You applied the most appropriate action area of the Ottawa
        Charter to the {match} exhibit.
      </p>
      <S.ResponseFooter>
        <CheckIconButton glow="blue" as={Link} to={goToExplain} />
      </S.ResponseFooter>
    </S.ResponseWrap>
  );
};

export const ResponseModuleComplete: FC<{
  completeContent: string;
  appHome?: string;
  completeTitle?: string;
  email: string;
  sending: boolean;
}> = ({
  appHome = '/app',
  completeContent = `You've completed Module 1.`,
  completeTitle = 'Congratulations',
  email = 'test@test.com',
  sending
}) => {
  return (
    <S.ResponseWrap>
      <p>
        <Finish />
      </p>
      <PageTitle>{completeTitle}</PageTitle>
      <p>{completeContent}</p>
      {sending ? (
        <p>
          We&apos;re currently sending your revision notes... please wait 30
          seconds
        </p>
      ) : (
        <p>
          We&apos;ve sent your revision notes to <br />
          {email}
        </p>
      )}

      <S.ResponseFooter>
        <Button glow="orange" as={Link} to={appHome}>
          Select New Module
        </Button>
      </S.ResponseFooter>
    </S.ResponseWrap>
  );
};

export const ResponseSubComplete: FC<{
  completeContent: string;
  moduleHome: string;
  completeTitle: string;
}> = ({ moduleHome, completeContent, completeTitle }) => {
  return (
    <S.ResponseWrap>
      <S.RingWrap>
        <GlowingRings />
      </S.RingWrap>
      <p>
        <Clap />
      </p>
      <PageTitle>{completeTitle}</PageTitle>
      <p>{completeContent}</p>
      <S.ResponseFooter>
        <CheckIconButton glow="blue" as={Link} to={moduleHome} />
      </S.ResponseFooter>
    </S.ResponseWrap>
  );
};

export const Response: FC<{
  type: string;
  match: string;
  backToMatch: string;
  goToExplain: string;
}> = ({ type = 'good', match, backToMatch, goToExplain }) => {
  return (
    <>
      {type === 'good' && (
        <ResponseGood backToMatch={backToMatch} goToExplain={goToExplain} />
      )}
      {type === 'strong' && (
        <ResponseStrong match={match} goToExplain={goToExplain} />
      )}
    </>
  );
};

export default Response;
