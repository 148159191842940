import React from 'react';
import Explain from 'src/components/svg/tutorial/Explain';
import Explore from 'src/components/svg/tutorial/Explore';
import Match from 'src/components/svg/tutorial/Match';
import Mission from 'src/components/svg/tutorial/Mission';
import Select from 'src/components/svg/tutorial/Select';

export const SlideIcons = [
  {
    id: 0,
    name: 'mission',
    icon: <Mission />
  },
  {
    id: 1,
    name: 'explore',
    icon: <Explore />
  },
  {
    id: 2,
    name: 'select',
    icon: <Select />
  },
  {
    id: 3,
    name: 'match',
    icon: <Match />
  },
  {
    id: 4,
    name: 'explain',
    icon: <Explain />
  }
];
