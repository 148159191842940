import Query from 'src/utils/Queries';
import tw, { styled } from 'twin.macro';
export const ResponseWrap = styled.div`
  ${tw`
    text-center
    flex
    flex-col
    justify-center
    flex-auto
  `};
  padding: 70px 0;
  max-height: 500px;
  ${Query.tablet} {
    ${tw`
      flex-initial
      p-0
    `};
    height: 880px;
    max-height: 880px;
  }
`;
export const ResponseFooter = styled.div`
  margin-top: 40px;
`;

export const RingWrap = styled.div`
  ${tw`absolute`};
  left: 50%;
  top: -35px;
  transform: translate(-50%, 0);
  width: 620px;
  height: 620px;
  ${Query.tablet} {
    top: -10px;
    width: 900px;
    height: 900px;
  }
`;
