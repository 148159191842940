// For "Module 1" like structures
import { navigate } from 'gatsby';
import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import LongAnswer from 'src/components/LongAnswer';
import Match from 'src/components/Match';
import { TopBar } from 'src/components/Splash';
import { Grid } from 'src/components/layout/Wrap';
import { useCurrentMatch, useCurrentMatchAnswer } from 'src/hooks/useData';
import { RootState } from 'src/state/reducers';
import { answersSelector } from 'src/state/selectors';
import { PageQuestion } from 'src/styles/Layout';
const MatchExplain: FC<{
  module: ModuleProps;
  submodule: SubModuleProps;
  path: string;
  answers?: any;
}> = ({ module, submodule, path, answers }) => {
  const currentMatch = useCurrentMatch(module, submodule, answers);
  const currentMatchAnswer = useCurrentMatchAnswer(module, submodule, answers);

  useEffect(() => {
    if (currentMatch === false && path === 'explain') {
      navigate(`/app/module/${module.slug}/${submodule.slug}/questionnaire`, {
        replace: true
      });
    }
  }, [currentMatch, path, module.slug, submodule.slug]);

  return (
    <>
      <TopBar>
        <span style={{ width: path === 'explain' ? '90%' : '10%' }} />
      </TopBar>
      <PageQuestion>
        {/* <PageBubbles mobileOnly /> */}
        <Grid>
          {path === 'explain' ? (
            <LongAnswer
              module={module}
              suggestedAnswer={
                currentMatchAnswer ? currentMatchAnswer.answer : ''
              }
              submodule={submodule}
              hasOttowa
              wordLimit={150}
              titles={{
                title: `Why was ${
                  currentMatch ? currentMatch.title : 'your choice '
                } the best match for this experience?`
              }}
            />
          ) : (
            <Match module={module} submodule={submodule} />
          )}
        </Grid>
      </PageQuestion>
    </>
  );
};

export default connect(
  (state: RootState) => ({ answers: answersSelector(state) }),
  null
)(MatchExplain);
