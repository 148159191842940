import { Router } from '@reach/router';
import React from 'react';
import Route from 'src/components/Route';
import ModuleComplete from 'src/components/interfaces/Complete/Module';
import SubmoduleComplete from 'src/components/interfaces/Complete/Submodule';
import MatchResponse from 'src/components/interfaces/MatchResponse';
import Module from 'src/components/interfaces/Module';
import Modules from 'src/components/interfaces/Modules';
import Questionnaire from 'src/components/interfaces/Questionnaire';
import Tutorial from 'src/components/interfaces/Tutorial';
import Welcome from 'src/components/interfaces/Welcome';

const App = () => (
  <Router>
    <Route path="/app" component={Modules} />
    <Route path="/app/module" component={Modules} />
    <Route path="/app/module/:moduleSlug/complete" component={ModuleComplete} />
    <Route path="/app/module/:moduleSlug/tutorial" component={Tutorial} />
    <Route path="/app/module/:moduleSlug" component={Module} />
    <Route
      path="/app/module/:moduleSlug/:submoduleSlug/complete"
      component={SubmoduleComplete}
    />
    <Route
      path="/app/module/:moduleSlug/:submoduleSlug/welcome"
      component={Welcome}
    />
    <Route
      path="/app/module/:moduleSlug/:submoduleSlug/questionnaire/match/:matchType"
      component={MatchResponse}
    />
    <Route
      path="/app/module/:moduleSlug/:submoduleSlug/questionnaire/*"
      component={Questionnaire}
    />

    {/* <Route
      path="/app/module/:moduleSlug/:experienceSlug/match-result"
      component={Questionnaire}
    />

    <Route
      path="/app/module/:moduleSlug/:experienceSlug/match-good"
      component={Questionnaire}
    />

    <Route
      path="/app/module/:moduleSlug/:experienceSlug/match-best"
      component={Questionnaire}
    />

    <Route
      path="/app/module/:moduleSlug/:experienceSlug/explain"
      component={Questionnaire}
    />
    <Route
      path="/app/module/:moduleSlug/:experienceSlug/explain-answer"
      component={Questionnaire}
    />
    <Route
      path="/app/module/:moduleSlug/:experienceSlug/experience-complete"
      component={Questionnaire}
    />
    <Route path="/app/module/:moduleSlug/complete" component={Questionnaire} /> */}

    {/*

    // Module complete
    <Route path="/app/module/:moduleSlug/complete" component={} />

    //Experience question


    // Experience complete
    <Route
      path="/app/module/:moduleSlug/:experienceSlug/complete"
      component={Complete}
    />

   */}
  </Router>
);

export default App;
