import { useLocation } from '@reach/router';
import { Formik, FormikProps } from 'formik';
import { navigate } from 'gatsby';
import React, { FC, useCallback, useEffect, useState } from 'react';
// import { connect } from 'react-redux';
// import Button from 'src/components/Button';
// import Tick from 'src/components/svg/Tick';
import ScrollAnimation from 'react-animate-on-scroll';
import { connect } from 'react-redux';
import Button from 'src/components/Button';
import CheckIconButton from 'src/components/CheckIconButton';
import LinkButton from 'src/components/LinkButton';
import OttawaModal from 'src/components/OttawaModal';
import TextArea from 'src/components/form/TextArea';
import { emptyValid } from 'src/components/form/validations';
import { useCurrentLongAnswer } from 'src/hooks/useData';
import { RootState } from 'src/state/reducers';
import { submitAnswer } from 'src/state/reducers/answers';
import { answersSelector } from 'src/state/selectors';
import { Center, PushToBottom } from 'src/styles/Alignment';
import { Page } from 'src/styles/Layout';
import { QuestionCategory } from 'src/styles/Typography';
import * as Yup from 'yup';
import { ExplainAnswer, ExplainAnswers, Form } from './styles';
// import * as S from './styles';

const explainValidation = Yup.object().shape({
  answer: emptyValid('Please enter an answer')
});

interface Answer {
  answer: string;
}
interface TitleProps {
  title: string;
}
const LongAnswer: FC<{
  module: ModuleProps;
  submodule: SubModuleProps;
  hasOttowa?: boolean;
  titles: TitleProps;
  wordLimit?: number;
  answers: Array<AnswerProps>;
  submitAnswerAction: Function;
  suggestedAnswer: string;
}> = ({
  module,
  submodule,
  hasOttowa = false,
  titles,
  wordLimit = 150,
  submitAnswerAction,
  answers,
  suggestedAnswer
}) => {
  const currentAnswer = useCurrentLongAnswer(module, submodule, answers);
  const [answer, setAnswer] = useState(currentAnswer ? currentAnswer : '');
  const [modal, setModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const location = useLocation();
  const completionURL = `/app/module/${module.slug}/${submodule.slug}/complete`;

  const manageSubmit = useCallback(
    values => {
      setAnswer(values.answer);
      navigate(`${location.pathname}?edit`);
    },
    [setAnswer, location]
  );

  useEffect(() => {
    if (location.search === '?edit') {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [location]);

  const confirmLongAnswer = useCallback(() => {
    submitAnswerAction({
      moduleId: module.id,
      id: submodule.id,
      answer
    });
    navigate(`${completionURL}`);
  }, [module, answer, submodule, submitAnswerAction, completionURL]);

  const toggleModal = useCallback(() => {
    setModal(!modal);
  }, [setModal, modal]);

  const disableEdit = useCallback(() => {
    window.history.back();
  }, []);

  return (
    <Page>
      <QuestionCategory>{module.longAnswerCategory}</QuestionCategory>

      {editMode ? (
        <>
          <h2>
            Compare your response to the high level sample below. How&apos;d you
            go?
          </h2>
          <ExplainAnswers>
            <ExplainAnswer>
              <h2>Your answer</h2>
              <p>{answer}</p>
            </ExplainAnswer>
            <ExplainAnswer provided>
              <h2>High level sample answer</h2>
              <p>{suggestedAnswer}</p>
            </ExplainAnswer>
          </ExplainAnswers>
          <Center>
            <PushToBottom>
              <p>
                <CheckIconButton
                  type="button"
                  onClick={confirmLongAnswer}
                  to={completionURL}
                />
              </p>
              <p>
                <LinkButton type="button" onClick={disableEdit}>
                  Edit my answer
                </LinkButton>
              </p>
            </PushToBottom>
          </Center>
        </>
      ) : (
        <>
          <h2>{titles.title}</h2>
          {hasOttowa && (
            <p>
              <ScrollAnimation offset={0} animateIn="fadeInUp">
                <Button
                  fullWidth
                  small
                  type="button"
                  glow="blue"
                  onClick={toggleModal}
                >
                  Refer to the Ottawa Charter
                </Button>
              </ScrollAnimation>
            </p>
          )}
          <Formik
            initialValues={{
              answer
            }}
            id="explainForm"
            validationSchema={explainValidation}
            onSubmit={manageSubmit}
          >
            {(formikProps: FormikProps<Answer>) => (
              <Form noValidate>
                <Center>
                  <TextArea required wordLimit={wordLimit} name="answer" />
                  <PushToBottom>
                    <CheckIconButton
                      disabled={formikProps.values.answer === ''}
                      type="submit"
                    />
                  </PushToBottom>
                </Center>
              </Form>
            )}
          </Formik>
        </>
      )}

      {hasOttowa && (
        <OttawaModal
          show={modal}
          toggleModal={toggleModal}
          moduleId={module.id}
        />
      )}
    </Page>
  );
};
const mapDispatchToProps = {
  submitAnswerAction: submitAnswer
};

export default connect(
  (state: RootState) => ({ answers: answersSelector(state) }),
  mapDispatchToProps
)(LongAnswer);
